.card-register {
  background-color: #FF8F5E;
  border-radius: 8px;
  color: #fff;
  max-width: 350px;
  margin: 20px 0 70px;
  min-height: 400px;
  padding: 30px;

  & label {
    margin-top: 15px;
  }
  & .card-title {
    color: #B33C12;
    text-align: center;
  }
  & .btn {
    margin-top: 15px;
  }
  & .forgot {
    text-align: center;
  }

  .input-group-text {
    border: 1px solid $white-color;
  }
}
