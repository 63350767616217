@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

*,
body {
  font-size: 16px;
  font-weight: 400;
  font-family: "Be Vietnam Pro", sans-serif !important;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.page-header {
  background-size: cover;
  background-repeat: no-repeat;
}

.btn-primary {
  background: linear-gradient(113.96deg, #f869d5 0%, #5650de 100%);
  border: none;
}

.card-register {
  min-height: 495px;
  max-width: 100%;
  width: 100%;
  border-radius: 50px;
  background: linear-gradient(
    341.1deg,
    rgba(110, 226, 245, 0.3) -28.66%,
    rgba(100, 84, 240, 0.3) 87.23%
  );
  padding: 50px 12% 50px;
}

.card-register .title {
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 50px;
}

.card-register .sub-title {
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: 500;
}

.card-register .register-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-register .register-form .form-group {
  width: calc(100% - 130px);
}

.register-form input {
  font-size: 16px;
  font-weight: 500;
}

.card-register .form-check {
  margin-top: 25px;
  display: flex;
}

.form-check .form-check-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: -2px;
}

.card-register .register-form button {
  display: flex;
  align-items: center;
  width: 120px;
  height: 40px;
  border-radius: 6px;
  border: 1px solid #00ffed;
  background-color: transparent;
  color: #00ffed;
  text-transform: capitalize;
  font-size: 16px;
  justify-content: center;
}

.card-register .register-form button.disabled {
  cursor: no-drop;
}
.form-check .form-check-sign::before,
.form-check .form-check-sign::after {
  background: #faac30;
  color: #fff;
  margin-top: 6px;
  font-size: 20px;
  padding-top: 2px;
}

.img-question {
  padding-right: 10px;
}

.form-control-feedback {
  padding-left: 0px;
  font-size: 12px;
  color: #f5593d;
  padding-left: 0px !important;
  margin-bottom: 10px;
}

.otp-input {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 5px;
}
.otp-input input,
.otp-input input:focus,
.otp-input input:focus-visible {
  width: 20% !important;
  padding: 0px 10px !important;
  background-color: transparent;
  border: none;
  border-bottom: 5px solid #fff;
  border-radius: 0px;
  text-align: center;
  color: #fff;
  font-size: 48px;
  font-weight: 600;
  outline: none;
}

.otp-input input[type="number"]::-webkit-outer-spin-button,
.otp-input input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.otp-input input[type="number"] {
  -moz-appearance: textfield;
}

.btn-custom {
  border-radius: 6px;
  background: linear-gradient(113.96deg, #00ffed 0%, #00b8ba 100%);
  border: none;
  border-image-source: linear-gradient(113.96deg, #00ffed 0%, #00b8ba 100%);
  text-transform: capitalize;
  font-size: 16px;
  height: 40px;
  line-height: 38px;
  padding: 0px 15px;
}

.navbar {
  background-color: #090042;
}

.navbar-collapse::after {
  background-color: #090042 !important;
}

.btn-custom.disabled {
  cursor: no-drop;
}

a {
  color: #00ffed !important;
  font-weight: 400;
  text-decoration: underline;
}

.red {
  color: red !important;
}

@media (min-width: 1400px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1400px;
  }
}

@media (max-width: 768px) {
  .mb-hide {
    display: none !important;
  }

  .card-register {
    padding: 0px 20px 30px;
    min-height: 350px;
  }

  .card-register .register-form button {
    font-size: 14px;
    padding: 0px;
    width: 100px;
    margin-top: 42px;
  }

  .card-register .register-form .form-group {
    width: calc(100% - 110px);
  }

  .form-check .form-check-label {
    font-size: 12px;
  }

  .card-register .sub-title {
    margin-bottom: 20px;
  }

  .card-register .title {
    margin-top: 30px;
  }
}

.navbar-collapse .navbar-nav > .nav-item > .nav-link:active {
  color: white !important;
}

@media (max-width: 767px) {
  .dropdown-metadata {
    position: absolute !important;
    background-color: #fff !important;
  }
  .dropdown-metadata .dropdown-item:hover {
    background-color: #f7765f !important;
  }
  .dropdown-metadata .dropdown-item:first-child:hover {
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
  }
  .dropdown-metadata .dropdown-item:last-child:hover {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
  }
  .dropdown.show .dropdown-metadata {
    display: block;
    position: static;
    float: none;
    background: white !important;
  }
  .nav-open .wrapper {
    transform: translateX(0px) !important;
  }
  .navbar-collapse .navbar-nav .nav-item .navbar-item {
    color: #00ffed !important;
  }
  .navbar-collapse .navbar-nav .nav-item .navbar-item:hover {
    color: white !important;
  }
}

@media (min-width: 768px) {
  .lg-hide {
    display: none !important;
  }
}

.text-center {
  text-align: center;
}

.text-xs {
  font-size: 0.75rem; /* 12px */
  line-height: 1rem; /* 16px */
}

.text-sm {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
}

.footer-copyright {
  position: fixed;
  bottom: 30px;
  left: 0;
  font-weight: 800;
  font-size: 16px;
}

.nv-popoverContent {
  @media screen and (max-width: 768px) {
    z-index: 100 !important;
  }
}
.nv-dropdownContent {
  @media screen and (max-width: 768px) {
    z-index: 102 !important;
  }
}

.inbox-container {
  text-align: right;
  margin: 5px 5px 0px 0px;
}
.nv-popoverContent {
  margin-right: 10px;
}
.nv-inboxContent .nv-preferences__button {
  display: none;
}

.nv-notificationListContainer + div {
  display: none;
}
